import React from 'react'
import Slider from 'react-slick'
import { LazyLoadImage } from 'react-lazy-load-image-component'

const ImageGallerySlider = () => {
    const settings = {
        className: 'center',
        infinite: true,
        loop: true,
        centerPadding: '60px',
        slidesToShow: 1,
        speed: 500,
        rows: 2,
        slidesPerRow: 3,
        nextArrow: <img src="/images/rightIcon.svg" />,
        prevArrow: <img src="/images/leftIcon.svg" />,
        responsive: [
            {
                breakpoint: 940,
                settings: {
                    rows: 3,
                    slidesPerRow: 3,
                },
            },
            {
                breakpoint: 650,
                settings: {
                    rows: 3,
                    slidesPerRow: 2,
                },
            },
        ],
    }

    return (
        <div>
            <Slider {...settings}>
                {[...Array(6)].map((data, index) => (
                    <div>
                        <LazyLoadImage
                            src={`/images/slider/gl${index + 1}.webp`}
                            effect="blur"
                            placeholderSrc="/images/imageLoading.svg"
                            height="100%"
                            width="100%"
                            alt="Next Lot"
                        />
                    </div>
                ))}
            </Slider>
        </div>
    )
}

export default ImageGallerySlider
