import React, { useState, useEffect } from 'react'
import auctionSearchDetails from '../../actions/auctionSearchAction'
import { useDispatch, connect } from 'react-redux'
import GridView from '../../components/molecules/ProductCard/GridView'
import './PastAuction.css'
import Pagination from '@material-ui/lab/Pagination'
import { getPaginationCount, onImageError, setDecimalPoint } from '../../Utils/Utility'
import { useHistory } from 'react-router-dom'
import Loader from '../../components/molecules/Loader'

const PastAuctions = (props) => {
    const dispatch = useDispatch()
    const history = useHistory()
    const [pastAuctions, setPastAuctions] = useState([])
    const [totalCount, setTotalCount] = useState('')
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        dispatch(
            auctionSearchDetails({
                // auc_type: '',
                is_auction: 1,
                auc_type: 2,
                auction_only: 1,
                lot_images: 1,
                orderby: '2',
                page: 1,
                searchbar: '',
                sh_limit: '10',
                type: 'closed',
                limit: 30,
            }),
        )
        setLoading(true)
    }, [])

    useEffect(() => {
        if (props.auctionSearch.auctionData.length) {
            setPastAuctions(props.auctionSearch.auctionData)
            setTotalCount(props.auctionSearch.pagecount)
            setLoading(false)
        }
    }, [props.auctionSearch.auctionData])

    return (
        <>
            {loading ? (
                <Loader isLoading={loading} />
            ) : (
                <div className="customContainer pastAuctionContainer">
                    <h3 className="text-center pt-5 pb-5">
                        <b>Past Auctions</b>
                    </h3>
                    <div className="pastGridView">
                        {pastAuctions ? (
                            <>
                                {pastAuctions.map((data, index) => (
                                    <GridView
                                        imgSrc={process.env.REACT_APP_PRODUCT_IMG + data.file_name}
                                        Name={data.ptitle}
                                        descrp={data.description}
                                        label="View Auction"
                                        id={data.id}
                                        startTime={data.date_added}
                                        endTime={data.date_closed}
                                        closedAuction
                                    />
                                ))}
                            </>
                        ) : (
                            'No Past Auctions Found'
                        )}
                    </div>
                    <div className="pageNo">
                        {totalCount !== '' && (
                            <Pagination
                                count={getPaginationCount(totalCount, 10)}
                                onChange={(e, value) => {
                                    let query = new URLSearchParams(props.location.search)
                                    query.set('page', value)
                                    history.push({
                                        pathname: props.location.pathname,
                                        search: '?' + query.toString(),
                                    })
                                }}
                                page={parseInt(1)}
                            />
                        )}
                    </div>
                </div>
            )}
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        auctionSearch: state.auctionSearch ? state.auctionSearch : '',
    }
}

export default connect(mapStateToProps, null)(PastAuctions)
