import React, { useState, useEffect } from 'react'
import CheckBox from '../../components/atoms/CheckBox/index'
import PrimaryButton from '../../components/atoms/PrimaryButton/index'
import Dashboard from '../../views/Dashboard/Dashboard'
import Swal from 'sweetalert2'
import axios from '../../Utils/Utility/axiosconfig'
import './Notification.css'

const arr = []

const Notification = (props) => {
    const [state, setstate] = useState({
        bid: false,
        won: false,
        outbid: false,
        watchlist: false,
        save: false,
        id: '',
    })

    const getAllNotifications = async () => {
        const res = await axios.post(`mobileapi/getnotification`, {})
        try {
            if (res.data.success === 'yes') {
                setstate({
                    ...state,
                    watchlist: res.data.data[0].email.includes('1') ? true : false,
                    bid: res.data.data[0].email.includes('2') ? true : false,
                    outbid: res.data.data[0].email.includes('3') ? true : false,
                    won: res.data.data[0].email.includes('4') ? true : false,
                    id: res.data.data[0].id,
                })
            }
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        getAllNotifications()
    }, [])

    const handleChange = (e) => {
        if (e.target.checked) {
            setstate({ ...state, [e.target.name]: true })
        } else {
            setstate({ ...state, [e.target.name]: false })
        }
    }

    const updateNotification = async () => {
        try {
            if (state.watchlist) {
                arr.push('1')
            }
            if (state.bid) {
                arr.push('2')
            }
            if (state.outbid) {
                arr.push('3')
            }
            if (state.won) {
                arr.push('4')
            }

            const res = await axios.post(`mobileapi/updatenotification`, {
                notify_id: state.id ? state.id : 0,
                email_settings: arr.toString(),
            })

            if (res.data.success === 'yes') {
                Swal.fire({
                    title: 'Notifications Updated Successfully',
                    icon: 'success',
                    timer: 2000,
                    showConfirmButton: false,
                    position: 'center',
                })
            }
        } catch (err) {
            console.log(err)
        }
    }

    return (
        <div>
            <Dashboard>
                <div className="Notification-Panel">
                    <table className="table">
                        <thead className="thead-light">
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Notification</th>
                                <th scope="col">Email</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th scope="row">1</th>
                                <td>Bid Submitted</td>
                                <td>
                                    <CheckBox
                                        name="bid"
                                        value={state.bid}
                                        onchange={handleChange}
                                        checked={state.bid}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th scope="row">2</th>
                                <td>Item added to Watchlist</td>
                                <td>
                                    <CheckBox
                                        name="watchlist"
                                        value={state.watchlist}
                                        onchange={handleChange}
                                        checked={state.watchlist}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th scope="row">3</th>
                                <td>Won Item</td>
                                <td>
                                    <CheckBox
                                        name="won"
                                        value={state.won}
                                        onchange={handleChange}
                                        checked={state.won}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <th scope="row">4</th>
                                <td>Outbid on Item</td>
                                <td>
                                    <CheckBox
                                        label={null}
                                        name="outbid"
                                        value={state.outbid}
                                        checked={state.outbid}
                                        onchange={handleChange}
                                    />
                                </td>
                            </tr>
                            {/* <tr>
                                <th scope="row">5</th>
                                <td>New Product Added</td>
                                <td>
                                    <CheckBox />
                                </td>
                            </tr> */}
                        </tbody>
                    </table>
                    <div>
                        <PrimaryButton label="Save" type="button" onClick={updateNotification} />
                    </div>
                </div>
            </Dashboard>
        </div>
    )
}

export default Notification
